export default {
  "environment": "dev",
  "appSync": {
    "url": "https://r233vmf4onh55h472v2qbjoqy4.appsync-api.us-west-2.amazonaws.com/graphql",
    "region": "us-west-2"
  },
  "identityPool": {
    "identityPoolID": "us-west-2:b302fdfe-1ad7-4238-9d03-b3893cbaf5d8",
    "region": "us-west-2"
  },
  "userPool": {
    "userPoolID": "us-west-2_T3MC88o8J",
    "userPoolRegion": "us-west-2",
    "iOSAppID": "5cr1rf7aq80gn5o33tkt0om6cp",
    "iOSAppSecret": "547jpijk3nrrngm0gdk9egtpe62dh3l2qfpn9dt83dndb7pea8o",
    "macOSAppID": "6tl7bg9ru3jjtplq5uml5d5her",
    "macOSSecret": "tutfjtqggjaeqbi01a4ghvvnp94i4cff8dtdgvfdm7bpmpcid69",
    "webAppID": "6oa857ufa00b8dkl9go9eujqdu",
    "cognitoAuthDomain": "dev.id.tracknotes.io"
  },
  "apiGateway": {
    "url": "https://tutp2qojgi.execute-api.us-west-2.amazonaws.com/dev",
    "region": "us-west-2"
  },
  "storage": {
    "region": "us-west-2",
    "mixBucket": "tracknotes.mix-upload.us-west-2.dev"
  },
  "webOAuth": {
    "redirectSignIn": "https://dev.app.tracknotes.io/",
    "redirectSignOut": "https://dev.app.tracknotes.io/",
    "cognitoAuthDomain": "dev.id.tracknotes.io"
  },
  "analytics": {
    "iOS": {
      "projectID": "851e0da027e04c07a5bd7f04ebf7237f",
      "region": "us-east-1"
    }
  },
  "web": {
    "webShareDomain": "dev.t.tracknotes.io",
    "webAppDomain": "dev.app.tracknotes.io"
  },
  "awsconfiguration": "{\"UserAgent\":\"aws-amplify/cli\",\"Version\":\"0.1.0\",\"IdentityManager\":{\"Default\":{}},\"AppSync\":{\"Default\":{\"ApiUrl\":\"https://r233vmf4onh55h472v2qbjoqy4.appsync-api.us-west-2.amazonaws.com/graphql\",\"Region\":\"us-west-2\",\"AuthMode\":\"AMAZON_COGNITO_USER_POOLS\",\"ClientDatabasePrefix\":\"TrackNotes dev us-west-2\"}},\"CredentialsProvider\":{\"CognitoIdentity\":{\"Default\":{\"PoolId\":\"us-west-2:b302fdfe-1ad7-4238-9d03-b3893cbaf5d8\",\"Region\":\"us-west-2\"}}},\"CognitoUserPool\":{\"Default\":{\"PoolId\":\"us-west-2_T3MC88o8J\",\"Region\":\"us-west-2\",\"AppClientId\":\"5cr1rf7aq80gn5o33tkt0om6cp\",\"AppClientSecret\":\"547jpijk3nrrngm0gdk9egtpe62dh3l2qfpn9dt83dndb7pea8o\",\"PinpointAppId\":\"851e0da027e04c07a5bd7f04ebf7237f\"}},\"FacebookSignIn\":{\"Permissions\":\"public_profile\"},\"Auth\":{\"Default\":{\"OAuth\":{\"WebDomain\":\"dev.id.tracknotes.io\",\"AppClientId\":\"5cr1rf7aq80gn5o33tkt0om6cp\",\"AppClientSecret\":\"547jpijk3nrrngm0gdk9egtpe62dh3l2qfpn9dt83dndb7pea8o\",\"SignInRedirectURI\":\"tracknotes://signin/\",\"SignOutRedirectURI\":\"tracknotes://signout/\",\"Scopes\":[\"phone\",\"email\",\"openid\",\"profile\",\"aws.cognito.signin.user.admin\"]}}},\"S3TransferUtility\":{\"Default\":{\"Bucket\":\"tracknotes.mix-upload.us-west-2.dev\",\"Region\":\"us-west-2\"}},\"PinpointAnalytics\":{\"Default\":{\"AppId\":\"851e0da027e04c07a5bd7f04ebf7237f\",\"Region\":\"us-east-1\"}},\"PinpointTargeting\":{\"Default\":{\"Region\":\"us-east-1\"}}}"
};